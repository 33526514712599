/* .App {
  text-align: center;
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@import url("https://fonts.googleapis.com/css2?family=Sora");
html,
body {
  font-family: "Sora", sans-serif;
}

.w-20 {
  width: 20%;
}

.w-10 {
  width: 10%;
}

.w-40 {
  width: 40%;
}

table {
  table-layout: fixed; /* will switch the table-layout algorythm */
}

.center-content {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-body {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}
.sidebar {
  top: 0;
  bottom: 0;
  left: 0;
  /* z-index: 100; */
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgb(0 0 0 / 10%);
}

#tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: -1; /* if you use -1 you have to set to `"window"` the interactivity.detectsOn property */
}

.progress {
  height: 8px;
  border-radius: 1rem;
  transition: 1s ease-out;
  /* transition-delay: 0.5s; */
}

.orange-progress-bar .progress-bar {
  background-color: #e7816c;
}

#tsconfetti {
  /* position: relative; */
  transition: 1s ease-out;
}
